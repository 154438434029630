<div *ngIf="trackingData?.id" class="modal-content summary-modal oley">
    <img (click)="close()" src="assets/images/orange-cross.png" alt="close" class="orange-cross" >
    <div class="detail-header">
        <h3 class="text-capitalize">
            <span *ngIf="selectedJob?.patientId?.patientName || selectedJob?.patientId?.Patient_First_Name">
                {{selectedJob?.patientId?.patientName ? selectedJob?.patientId?.patientName : selectedJob?.patientId?.Patient_Last_Name+' '+selectedJob?.patientId?.Patient_First_Name }}
                | 
            </span>
            #{{selectedJob?.jobNumber}}
        </h3>
    </div>
    <div class="modal-body">
        <div>
            <ng-container [ngSwitch]="trackingData?.carrier">
                <img *ngSwitchCase="'UPSMailInnovations'" class="carrier-logo" 
                src="https://assets.track.easypost.com/shared-assets/carriers/ups-logo.svg"
                onerror="this.src='https://via.placeholder.com/1x1'" alt="UPS" />
                <img *ngSwitchDefault class="carrier-logo" 
                src="https://assets.track.easypost.com/shared-assets/carriers/{{trackingData?.carrier?.toLowerCase()}}-logo.svg"
                onerror="this.src='https://via.placeholder.com/1x1'" alt="carrier" />
            </ng-container>
            <div class="order-track"> <span>Carrier:</span> <span> {{trackingData?.carrier}}</span>
            </div>
            <div class="order-track"><span>Tracking Number:</span> <span> {{trackingData?.tracking_code}}</span></div>
            <div *ngIf="trackingData?.status != 'delivered'" class="order-track"><span>Expected Delivery Date:</span> <span> {{trackingData?.est_delivery_date | date: "MM/dd/yyyy" }} at {{trackingData?.est_delivery_date | date: "hh:mm a" }}</span>
            </div>
            <div *ngIf="trackingData?.status == 'delivered'" class="order-track"><span>Delivered:</span> <span> {{items[0]['datetime'] | date: "MM/dd/yyyy hh:mm a" }}</span> </div>
        </div>
        <br/>
        <div *ngIf="job?.jobSubType=='DTP'" class="alert alert-warning" role="alert">
            <i>There may be a slight delay from the time of delivery and the delivery confirmation email and/or text message to the patient due to message queues.</i>
        </div>
        <div *ngIf="!items || items?.length == 0" class="alert alert-danger" role="alert">
            <i>The shipment carrier hasn't provided any updates to this tracking number yet. Please check back in a few hours - or tomorrow - for an update.</i>
        </div>
        <div style="max-height: 500px;overflow-y: scroll;">
            <div *ngFor="let item of items;let i=index" class="summary-info">
                <div *ngIf=" !i || ( item.datetime | date: 'shortDate') !== (items[i-1].datetime | date: 'shortDate' ) "class="summary-day">
                    <p>{{item?.datetime | date: "EEEE, MMMM d"}}</p>
                </div>
                <div class="summary-status">
                    <h4 class="summary-time">{{item?.datetime | date: "hh:mm a"}}</h4>
                    <div class="summary-desc">
                        <p>{{item?.message}}</p>
                        <p class="desc-location">{{item?.tracking_location?.city || '--'}}, {{item?.tracking_location?.state}}</p>
                    </div>
                </div>
            </div>
        </div>
        <br/><br/>

        <!-- <iframe src="https://track.easypost.com/djE6dHJrX2RlMWI5ZmVkZGVjMDQyZDlhM2U0MDczYmU4YzFkZDYx"></iframe> -->
    </div>
</div>