import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-tracking-details',
  templateUrl: './tracking-details.component.html',
  styleUrls: ['./tracking-details.component.css']
})
export class TrackingDetailsComponent implements OnInit {

  selectedJob:any={};
  job:any={};
  trackingData:any={};
  items:any[];

  constructor(
    private api:ApiService,
    private toastrService:ToastrService,
    private bsModalRef: BsModalRef<TrackingDetailsComponent>,
  ) { }

  ngOnInit(): void {
    this.job = JSON.parse(JSON.stringify(this.selectedJob));
    let input:any={};
    input.trackingNumber = this.job.trackingNumber;
    this.api.getData('trackingDetails',input).subscribe(r=>{
      this.trackingData = r['data'];
      this.items = this.trackingData.tracking_details.map(item=>{
        // item.datetime = item.datetime.replace('T',' ');
        item.datetime = item.datetime.replace('Z','');
        return item;
      }).reverse();
      this.trackingData.est_delivery_date = this.trackingData.est_delivery_date.replace('Z','');
    },error=>{
      this.toastrService.error(error.error.message);
    });
  }

  close(){
    this.bsModalRef.hide();
  }


}
