<div class="detail-modal">
    <div class="modal-header">
        <h3 class="modal-title">Spend Threshold Not Met</h3>
    </div>
    <div class="detail-content p-3">
        <div class="balance text-red fw-bold">{{ threshold - batchTotal | currency:'USD'}}</div>
        <div class="alert alert-out">
            Are you sure you want to process this batch order? Based on the total
            cost of {{batchTotal | currency:'USD'}} in your batch queue, you are under the {{threshold | currency:'USD'}} threshold,
            and in turn, you will likely incur shipping charges from your distributor.
        </div>
       <div class="d-flex justify-content-around align-items-center mt-4 mb-4">
            <div class="text-center">
                <div class="out-title">Batch Cost</div>          
                <div class="out-val">{{batchTotal | currency:'USD'}}</div>
            </div>
            <div class="text-center">
                <div class="out-title">Spend Threshold</div>       
                <div class="out-val">{{threshold | currency:'USD'}}</div>
            </div>
            <div class="text-center">
                <div class="out-title">Spend Needed</div>   
                <div class="out-val">{{ threshold - batchTotal | currency:'USD'}}</div>
            </div>
       </div>
    </div>

    <div class="modal-footer text-center" style=" justify-content: center;">
        <button (click)="close()" class="btn btn-danger mb-3">No, keep batch in queue</button>
        <button (click)="proceed()" class="btn btn-success mb-3">Yes, process this batch</button>
    </div>
</div>