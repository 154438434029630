import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toNumber'
})
export class ToNumberPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    // console.log(typeof value);
    if(typeof value=='number'){
      return value;
    }
    if(typeof value=='string'){
      return parseFloat(value.replace(/,/g, ''));
    }
    return null;
  }

}
