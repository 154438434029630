import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-inactive-account',
  templateUrl: './inactive-account.component.html',
  styleUrls: ['./inactive-account.component.css']
})
export class InactiveAccountComponent implements OnInit {

  inactiveData:any={};
  constructor(
    private bsModalRef: BsModalRef<InactiveAccountComponent>,
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.bsModalRef.hide();
  }

}
