<div class="detail-modal">
    <div class="detail-content">
        <div class="modal-header">
            <h3 class="modal-title text-center w-100">Inactive Account</h3>
        </div>
        <div class="text-center mt-4 mb-4">
            <img src="assets/images/info-triangle.svg"/>
        </div>
        <div class="alert text-center">
            The office has canceled their subscription to the platform facilitating this interaction. Please call the office for more information or assistance.
        </div>
    </div>

    <div class="modal-footer text-center" style=" justify-content: center;">
        <!-- <button (click)="close()" class="btn btn-danger mb-3">Close</button> -->
        <a  *ngIf="inactiveData?.storePhone" class="btn-primary btn mx-2 mt-4" [style.background]="inactiveData?.brandColor" href="tel:{{inactiveData?.storePhone}}">Call Us</a>

    </div>
</div>
