import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-check-batch',
  templateUrl: './check-batch.component.html',
  styleUrls: ['./check-batch.component.css']
})
export class CheckBatchComponent implements OnInit {

  public onClose: Subject<boolean>;
  batchTotal:number;
  threshold:number;

  constructor(
    private bsModalRef: BsModalRef<CheckBatchComponent>,
  ) { }

  ngOnInit(): void {
  }

  proceed(){
    this.onClose.next(true);
    this.bsModalRef.hide();
  }
  close(){
    this.bsModalRef.hide();
  }

}
