import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.css']
})
export class ViewImageComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: any;

  title:any;
  url:any;
  // zoomLevel: number = 1;
  // maxZoomLevel: number = 1.5; 
  // minZoomLevel: number = 0.5;
  constructor(
    private bsModalRef: BsModalRef<ViewImageComponent>,
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.bsModalRef.hide();
  }

  async downloadImage() {
    let time = new Date().getTime();
    const image = await fetch(this.url,{
      'mode':'no-cors'
      // method:'GET',
      // headers:{
      // "Access-Control-Request-Method": "GET"
      // }
    });
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
    console.log('====>',image)
    console.log('imageBlog====>',imageBlog)
    console.log('imageURL====>',imageURL);
    const link = document.createElement('a')
    link.href = imageURL
    link.download = time+'.png';
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  // zoomIn(): void {
  //   console.log("zoomOut")
  //   this.zoomLevel = Math.min(this.zoomLevel + 0.1, this.maxZoomLevel);
  //   this.zoomLevel = parseFloat(this.zoomLevel.toFixed(1)); 
  // }

  // zoomOut(): void {
  //   console.log("zoomOut")
  //   this.zoomLevel = Math.max(this.zoomLevel - 0.1, this.minZoomLevel);
  //   this.zoomLevel = parseFloat(this.zoomLevel.toFixed(1)); 
    
  // }
  
 
}
