import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderDate'
})
export class OrderDatePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(!value){
      return null;
    }
    return value.split('T')[0];
  }

}
