import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phonenumber'
})
export class PhonenumberPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(!value){
      return '';
    }
    value = value.replace(/\D/g,'');
    let prefix = value.slice(0,-10);
    let lastTen = value.slice(-10);
    return prefix +' '+ lastTen
        .replace(/\D/g, '')
        .replace(/^(\d)/, '($1')
        .replace(/^(\(\d{3})(\d)/, '$1) $2')
        .replace(/(\d{3})(\d{1,5})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
  }

}
