<div class="detail-modal">
    <img (click)="close()" src="assets/images/orange-cross.png" alt="close" class="orange-cross" style="z-index: 1;" >
    <div class="detail-header d-flex justify-content-center align-items-center">

    <!-- <div class="zoom-controls text-center mt-3">
        <button (click)="zoomIn()">+</button>
        <button (click)="zoomOut()">-</button>
        {{zoomLevel}} {{maxZoomLevel}} {{minZoomLevel}}
    </div> -->
        <h3 class="text-capitalize mx-auto">{{title}}</h3>
        <a href="{{url}}" target="_blank" class="download-btn">
            <span class="fa fa-download" style="color: #8e88f4;" aria-hidden="true"></span>
            <span style="font-weight: 600; margin-left: 5px;">Download</span>  
        </a>
    </div>
    <div class="detail-content text-center">
        <!-- <img [src]="url" [style.transform]="'scale(' + zoomLevel + ')'"
        [style.transition]="'transform 0.3s ease'"
        class="zoomable-image"/> -->
        <img [src]="url" />
    </div>
</div>