import { Injectable } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { DataService } from './data.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class InterceptorService  implements HttpInterceptor {

  constructor(
    private api:ApiService,
    private data:DataService,
    private router:Router,
    private toastr:ToastrService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Clone the request to add the new header.
    const token = localStorage.getItem("access_token");
    const region = localStorage.getItem("region");
    const store = localStorage.getItem("store");
    const enterpriseId = this.api.enterpriseId;
    if(token
      && /zipcodeapi.com/i.test(req.url) == false
      && /247appoint.com/i.test(req.url) == false
      ){
      req = req.clone({ headers: req.headers.set("authorization", token)});
    }
    //this.api.user && this.api.user.type == 'SUPERADMIN' && 
    if(region){
      req = req.clone({ headers: req.headers.set("region", region)});
    }
    if(store){
      req = req.clone({ headers: req.headers.set("store", store)});
    }
    // console.log('enterpriseId',enterpriseId, req.url);
    if(enterpriseId){
      req = req.clone({ headers: req.headers.set("enterpriseid", enterpriseId)});
    }
    if(this.api.showLoader){
      this.api.loader(true);
    }else{
      this.api.showLoader = true;
    }

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => { 
      if (event instanceof HttpResponse) {
        this.api.loader(false);
      }
    },
    (err: any) => {
      this.api.loader(false);
      if (err instanceof HttpErrorResponse) {
        if(err.error.statusCode==401){
          // localStorage.removeItem('access_token');
          console.log('UNABLE TO CONNECT',req,err);
          // if(/loginDetails/i.test(req.url)){
            // this.router.navigate(['/login']);
            // this.toastr.error(err.error.error);
          // }
        }
        if(err.error.statusCode==403){
          this.data.openModalInactiveAccount(err.error.data);
        }
      }
    }));
  }

}