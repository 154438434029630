<div class="detail-modal">
    <!-- <form action="" class="dashboard-form p-3">
        <div class="form-group">
            <input [(ngModel)]="search" type="text" placeholder="Search by title here..." class="form-control icon-control">
        </div>
    </form>
    <div *ngFor="let key of jobData" class="row single-field" [ngClass]="{'d-none':hideIt(key)}">
        <div class="col-md-6">{{key}}</div>
        <div class="col-md-6">{{selectedJob[key] || '--'}} </div>
    </div> -->
    <div class="detail-header">
        <h3 class="text-capitalize">
            {{selectedJob?.patientId?.patientName ? selectedJob?.patientId?.patientName : selectedJob?.patientId?.Patient_Last_Name+' '+selectedJob?.patientId?.Patient_First_Name }}
            |  #{{selectedJob?.jobNumber}}
            </h3>
    </div>

    <div class="detail-content scroll">
        <div class="detail-wrapper">
            <div class="batch-title line-title">Order information</div>
            <ul class="detail-description">
                <li class="each-description w-25">
                    <span class="desc-label">order number</span>
                    <span class="value">{{selectedJob?.jobNumber || '--'}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">order date</span>
                    <span class="value">{{selectedJob?.orderDate | orderDate | date:'MM/dd/yyyy'}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Business type</span>
                    <span class="value">{{selectedJob?.businessType || '--'}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Consultant</span>
                    <span class="value text-capitalize">{{ lower(selectedJob[prefix+'Consultant']) || '--'}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Office name</span>
                    <span class="value">{{selectedJob?.storeId?.name || '--'}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Office number</span>
                    <span class="value">{{selectedJob?.storeId?.number || '--'}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Ship Type</span>
                    <span class="value">{{selectedJob?.shipType || '--'}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Ship Speed</span>
                    <span class="value">{{selectedJob?.updatedData?.shipSpeed || 'Standard' | title}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Payment Type</span>
                    <span class="value">{{selectedJob[prefix+'Insurance_Carrier'] ? 'Insurance' : 'Cash'}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Carrier</span>
                    <span class="value">{{selectedJob[prefix+'Insurance_Carrier'] || '--'}}</span>
                </li>
                <!-- <li class="each-description w-25">
                    <span class="desc-label">Prescription Start</span>
                    <span class="value">{{selectedJob[prefix+'Associated_Rx_Start_Date'] | date:'MM/dd/yy' || '--'}}</span>
                </li> -->
                <!-- <li class="each-description w-25">
                    <span class="desc-label">Prescription Expiration</span>
                    <span class="value">{{selectedJob[prefix+'Associated_Rx_Expiration_Date'] | date:'MM/dd/yy' || '--'}}</span>
                </li> -->
                <li *ngIf="selectedJob?.purchaseReceiptUrl" class="each-description w-25">
                    <span class="desc-label">Patient Receipt</span>
                    <a href="{{selectedJob?.purchaseReceiptUrl}}" target="_blank" >
                        <img src="assets/images/pdf.svg" style="height:30px;width:auto" class="cursor-pointer" />
                    </a>
                </li>
            </ul>

            <!-- Patient Receipt *ngIf="selectedJob?.purchaseReceiptUrl" -->

        </div>

        <div *ngIf="type=='Eyeglass'" class="detail-wrapper">
            <div class="batch-title line-title">Frame Information</div>
            <ul class="detail-description">
                <li class="each-description w-100">
                    <span class="desc-label">frame name</span>
                    <span class="value">{{selectedJob?.Eyeglass_Frame_Name || '--'}}</span>
                </li>

                <li class="each-description">
                    <span class="desc-label">collection</span>
                    <span class="value">{{selectedJob?.Eyeglass_Frame_Collection || '--'}}</span>
                </li>
                <li class="each-description">
                    <span class="desc-label">Brand</span>
                    <span class="value">{{selectedJob?.Eyeglass_Frame_Brand || 'West Point Optical Group'}}</span>
                </li>
                <li class="each-description">
                    <span class="desc-label">Color</span>
                    <span class="value">{{selectedJob?.Eyeglass_Frame_Color || '--'}}</span>
                </li>
                <li class="each-description">
                    <span class="desc-label">Frame Type</span>
                    <span class="value">{{selectedJob?.Eyeglass_Frame_Type || '--'}}</span>
                </li>
                <li class="each-description">
                    <span class="desc-label">Item number</span>
                    <span class="value">{{selectedJob?.Eyeglass_Frame_Item_Number || '--'}}</span>
                </li>
                <li class="each-description">
                    <span class="desc-label">UPC Code</span>
                    <span class="value">{{selectedJob?.Eyeglass_Frame_UPC || '--'}}</span>
                </li>
                <li class="each-description">
                    <span class="desc-label">Eye</span>
                    <span class="value">{{selectedJob?.Eyeglass_Frame_Eye || '--'}}</span>
                </li>
                <li class="each-description">
                    <span class="desc-label">Bridge</span>
                    <span class="value">{{selectedJob?.Eyeglass_Frame_Bridge || '--'}}</span>
                </li>
                <li class="each-description">
                    <span class="desc-label">Temple</span>
                    <span class="value">{{selectedJob?.Eyeglass_Frame_Temple || '--'}}</span>
                </li>
                <li class="each-description">
                    <span class="desc-label">A, B, ED, DBL</span>
                    <span class="value">
                        {{selectedJob?.Eyeglass_Frame_A || '--'}},
                        {{selectedJob?.Eyeglass_Frame_B || '--'}},
                        {{selectedJob?.Eyeglass_Frame_ED || '--'}},
                        {{selectedJob?.Eyeglass_Frame_DBL || '--'}}
                    </span>
                </li>
            </ul>
        </div>

        <div *ngIf="type=='Eyeglass'" class="detail-wrapper">
            <div class="batch-title line-title">Eyeglass lens information</div>
            <div class="row">
                <div class="col-md-6">
                    <div class="lens-info">
                        <p class="text-underline">RIGHT</p>
                        <ul class="detail-description">
                            <li *ngFor="let key of LensRight" class="each-description w-50">
                                <span class="desc-label">{{ keyToName(key,'Eyeglass_Right_') }}</span>
                                <span class="value">{{selectedJob[key] || '--'}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="lens-info left-lens">
                        <p class="text-underline">LEFT</p>
                        <ul class="detail-description">
                            <li *ngFor="let key of LensLeft" class="each-description w-50">
                                <span class="desc-label">{{ keyToName(key,'Eyeglass_Left_') }}</span>
                                <span class="value">{{selectedJob[key] || '--'}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="type=='Eyeglass'" class="detail-wrapper apply-extra">
            <div class="batch-title line-title">Eyeglass Lens Extras</div>
            <div class="row">
                <div class="col-md-6">
                    <div class="lens-info">
                        <p class="text-underline">TINT</p>
                        <ul class="detail-description">
                            <li class="each-description w-50">
                                <span class="desc-label">Tint Type</span>
                                <span class="value">{{ selectedJob?.Eyeglass_Extra_Tint_Type || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Color</span>
                                <span class="value">{{ selectedJob?.Eyeglass_Extra_Tint_Color || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Comment</span>
                                <span class="value">{{ selectedJob?.Eyeglass_Extra_Tint_Color_Comment || '--'}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="lens-info">
                        <p class="text-underline">EDGE</p>
                        <ul class="detail-description">
                            <li class="each-description w-100">
                                <span class="desc-label">Type</span>
                                <span class="value">{{ selectedJob?.Eyeglass_Frame_Edge_Type || '--'}}</span>
                            </li>
                            <li class="each-description  w-100">
                                <span class="desc-label">Edge</span>
                                <span class="value">{{ selectedJob?.Eyeglass_Extra_Edge || '--'}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="lens-info lab-instruction">
                        <p class="text-underline">COATINGS</p>
                        <ul class="detail-description">
                            <li class="each-description w-50">
                                <span class="desc-label">01</span>
                                <span class="value">{{ selectedJob?.Eyeglass_Extra_Coating_1 || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">02</span>
                                <span class="value">{{ selectedJob?.Eyeglass_Extra_Coating_2 || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">03</span>
                                <span class="value">{{ selectedJob?.Eyeglass_Extra_Coating_3 || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">04</span>
                                <span class="value">{{ selectedJob?.Eyeglass_Extra_Coating_4 || '--'}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="lens-info lab-instruction">
                        <p class="text-underline">MISCELLANEOUS</p>
                        <ul class="detail-description">
                            <li class="each-description w-50">
                                <span class="desc-label">01</span>
                                <span class="value">{{ selectedJob?.Eyeglass_Extra_Misc_1 || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">02</span>
                                <span class="value">{{ selectedJob?.Eyeglass_Extra_Misc_2 || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">03</span>
                                <span class="value">{{ selectedJob?.Eyeglass_Extra_Misc_3 || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">04</span>
                                <span class="value">{{ selectedJob?.Eyeglass_Extra_Misc_4 || '--'}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="type=='Eyeglass'" class="detail-wrapper apply-extra">
            <div class="batch-title line-title">Eyeglass Lab Information</div>
            <ul class="detail-description">
                <li class="each-description w-100">
                    <span class="desc-label">Lab Instructions</span>
                    <span class="value">{{ selectedJob?.Eyeglass_Lab_Instructions || '--'}}</span>
                </li>
            </ul>
            <ul class="detail-description">
                <li class="each-description w-25">
                    <span class="desc-label">Vertex</span>
                    <span class="value">{{ selectedJob?.Eyeglass_Vertex || '--'}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Pentoscopic Tilt </span>
                    <span class="value">{{ selectedJob?.Eyeglass_Pantoscopic_Tilt || '--'}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">frame wrap</span>
                    <span class="value">{{ selectedJob?.Eyeglass_Frame_Wrap || '--'}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">VSP Auth</span>
                    <span class="value">{{ selectedJob?.Eyeglass_Eyeglass_VSP_Auth || '--'}}</span>
                </li>
            </ul>

            <div class="filter-info">
                <span class="desc-label">Lab to Make</span>
                <div class="form-group inline-group">
                    <div class="form-check-custom">
                        <input type="checkbox" class="form-check-input" id="customCheck" name="example1"
                           [checked]="selectedJob?.Eyeglass_Lab_To_Make_Frame == 'checked'" disabled>
                        <label class="custom-control-label" for="customCheck">Frame</label>
                    </div>
                    <div class="form-check-custom">
                        <input type="checkbox" class="form-check-input" id="customCheck2" name="example1"
                            [checked]="selectedJob?.Eyeglass_Lab_To_Make_Right_Lens == 'checked'" disabled>
                        <label class="custom-control-label" for="customCheck2">Right</label>
                    </div>
                    <div class="form-check-custom">
                        <input type="checkbox" class="form-check-input" id="customCheck3" name="example1"
                            [checked]="selectedJob?.Eyeglass_Lab_To_Make_Left_Lens == 'checked'" disabled>
                        <label class="custom-control-label" for="customCheck3">Left</label>
                    </div>
                    <div class="form-check-custom">
                        <input type="checkbox" class="form-check-input" id="customCheck4" name="example1"
                          [checked]="selectedJob?.Eyeglass_Lab_To_Make_Extras == 'checked'" disabled>
                        <label class="custom-control-label" for="customCheck4">Extras</label>
                    </div>
                </div>
            </div>


            <div class="filter-info">
                <span class="desc-label">Lens Specifications</span>
                <div class="form-group inline-group">
                    <div class="form-check-custom">
                        <input type="checkbox" class="form-check-input" id="customCheck5" name="example1"
                            [checked]="selectedJob?.Eyeglass_Near_Only == 'checked'" disabled>
                        <label class="custom-control-label" for="customCheck5">Near Only</label>
                    </div>
                    <div class="form-check-custom">
                        <input type="checkbox" class="form-check-input" id="customCheck6" name="example1"
                            [checked]="selectedJob?.Eyeglass_Uncut == 'checked'" disabled>
                        <label class="custom-control-label" for="customCheck6">Uncut</label>
                    </div>
                </div>
            </div>
            
        </div>


        <div *ngIf="type!='Eyeglass'" class="detail-wrapper">
            <div class="batch-title line-title">Prescription Information</div>
            <ul class="detail-description">
                    <li class="each-description w-25">
                        <span class="desc-label">Prescription Start</span>
                        <span class="value">{{selectedJob[prefix+'Associated_Rx_Start_Date'] ? (selectedJob[prefix+'Associated_Rx_Start_Date'] | date:'MM/dd/yy') : '--'}}</span>
                    </li>
                    <li class="each-description w-25">
                        <span class="desc-label">Prescription Expiration</span>
                        <span class="value">{{selectedJob[prefix+'Associated_Rx_Expiration_Date'] ? (selectedJob[prefix+'Associated_Rx_Expiration_Date'] | date:'MM/dd/yy') : '--'}}</span>
                    </li>
                    <li class="each-description w-25">
                        <span class="desc-label">Doctor</span>
                        <span class="value">{{selectedJob?.Soft_CL_Associated_Rx_Doctor || '--'}}</span>
                    </li>
              </ul>
            <div class="row">
                <div class="col-md-6">
                    <div class="lens-info left-lens">
                        <p class="text-underline">Right</p>
                        <ul class="detail-description">
                            <li class="each-description w-50">
                                <span class="desc-label">Manufacturer</span>
                                <span class="value">
                                    {{
                                        selectedJob?.rightArrellioManufacturerName 
                                        || selectedJob?.Soft_CL_Lens_Right_Manufacturer 
                                        || '--'
                                    }}
                                </span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Brand</span>
                                <span class="value">
                                    {{
                                        selectedJob?.rightArrellioStyleName  
                                        || selectedJob?.Soft_CL_Lens_Right_Style 
                                        || '--'
                                    }}
                                </span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Base</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Right_Base || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Diameter</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Right_Diameter || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Sphere</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Right_Sphere || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Cylinder</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Right_Cylinder || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Axis</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Right_Axis || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Add</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Right_Add || selectedJob?.Soft_CL_Lens_Right_Add_Power || '--'}}</span>
                            </li>
                            <li *ngIf="user?.enterpriseId?.PMS?.system == 'Revolution'" class="each-description w-50">
                                <span class="desc-label">Designation</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Right_Add_Designation || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Color</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Right_Color || '--'}}</span>
                            </li>
                            <li *ngIf="user?.enterpriseId?.PMS?.system == 'Revolution'"  class="each-description w-50">
                                <span class="desc-label">Pack Size</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Right_Pack_Size || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Quantity</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Right_Quantity || '--'}}</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="lens-info">
                        <p class="text-underline">Left</p>
                        <ul class="detail-description">
                            <li class="each-description w-50">
                                <span class="desc-label">Manufacturer</span>
                                <span class="value">
                                    {{
                                        selectedJob?.leftArrellioManufacturerName 
                                        || selectedJob?.Soft_CL_Lens_Left_Manufacturer 
                                        || '--'
                                    }}
                                </span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Brand</span>
                                <span class="value">
                                    {{
                                        selectedJob?.leftArrellioStyleName  
                                        || selectedJob?.Soft_CL_Lens_Left_Style 
                                        || '--'
                                    }}
                                </span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Base</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Left_Base || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Diameter</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Left_Diameter || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Sphere</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Left_Sphere || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Cylinder</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Left_Cylinder || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Axis</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Left_Axis || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Add</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Left_Add || selectedJob?.Soft_CL_Lens_Left_Add_Power || '--'}}</span>
                            </li>
                            <li *ngIf="user?.enterpriseId?.PMS?.system == 'Revolution'" class="each-description w-50">
                                <span class="desc-label">Designation</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Left_Add_Designation || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Color</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Left_Color || '--'}}</span>
                            </li>
                            <li *ngIf="user?.enterpriseId?.PMS?.system == 'Revolution'"  class="each-description w-50">
                                <span class="desc-label">Pack Size</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Left_Pack_Size || '--'}}</span>
                            </li>
                            <li class="each-description w-50">
                                <span class="desc-label">Quantity</span>
                                <span class="value">{{selectedJob?.Soft_CL_Lens_Left_Quantity || '--'}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
         
            </div>
        </div>

        <div *ngIf="type!='Eyeglass'" class="detail-wrapper apply-extra">
            <div class="batch-title line-title">Distributor Information</div>
            <ul class="detail-description">
                <!-- <li class="each-description">
                    <span class="desc-label">Supply Source</span>
                    <span class="value">{{ selectedJob[prefix+'Supply_Source'] || '--'}}</span>
                </li> -->
                <li class="each-description">
                    <span class="desc-label">Distributor</span>
                    <span class="value">{{ selectedJob?.distributor || '--'}}</span>
                </li>
                <li class="each-description">
                    <span class="desc-label">Order Number</span>
                    <span class="value">{{ selectedJob?.visionWebOrderNumber || '--'}}</span>
                </li>
                <li class="each-description">
                    <span class="desc-label">Invoice Number</span>
                    <span class="value">{{ selectedJob?.invoiceNumber || '--'}}</span>
                </li>
                <li class="each-description w-100">
                    <span class="desc-label">Order Instructions</span>
                    <span class="value">{{ selectedJob[prefix+'Dispensing_Notes'] || '--'}}</span>
                </li>
            </ul>
        </div>


        <!-- patient Information -->
        <div *ngIf="selectedJob?.businessType=='Exam Walkout' " class="detail-wrapper">

            <div class="batch-title line-title">Payment Information</div>
            <ul class="detail-description">
                <li class="each-description w-25">
                    <span class="desc-label">Payment Type</span>
                    <span class="value">{{selectedJob?.updatedData?.paymentType || '--'}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Carrier</span>
                    <span class="value">{{selectedJob?.updatedData?.insuranceCarrier || '--'}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Member ID</span>
                    <span class="value" *ngIf="selectedJob?.updatedData?.paymentType!='Cash'">{{selectedJob?.updatedData?.insuranceMemberId || '--'}}</span>
                    <span class="value" *ngIf="selectedJob?.updatedData?.paymentType=='Cash'">--</span>
                </li>
                <li  *ngIf="selectedJob?.updatedData?.insuranceCarrier=='VSP'" class="each-description w-25">
                    <span class="desc-label">Authorization Number</span>
                    <span class="value">{{selectedJob?.authorizationNumber || '--'}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Retail Price</span>
                    <span class="value" *ngIf="selectedJob?.updatedData?.Retail_Price">${{selectedJob?.updatedData?.Retail_Price}}</span>
                    <span class="value" *ngIf="!selectedJob?.updatedData?.Retail_Price">$0.00</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Insurance Allowance</span>
                    <!-- <span class="value">{{selectedJob?.updatedData?.paymentType=='Cash' ? '--' : selectedJob?.updatedData?.insuranceAllowance | currency:'USD' || '--'}}</span> -->
                    <span class="value" *ngIf="selectedJob?.updatedData?.paymentType!='Cash'">${{selectedJob?.updatedData?.insuranceAllowance || '0.00'}}</span>
                    <span class="value" *ngIf="selectedJob?.updatedData?.paymentType=='Cash'">--</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Insurance Copay</span>
                    <!-- <span class="value">{{selectedJob?.updatedData?.paymentType=='Cash' ? '--' : selectedJob?.updatedData?.insuranceCopay | currency:'USD' || '--'}}</span> -->
                    <span class="value" *ngIf="selectedJob?.updatedData?.paymentType!='Cash'">${{selectedJob?.updatedData?.insuranceCopay || '0.00'}}</span>
                    <span class="value" *ngIf="selectedJob?.updatedData?.paymentType=='Cash'">--</span>
                </li>
                <!-- Insurance && Davis -->
                <li *ngIf="selectedJob?.updatedData?.paymentType=='Insurance'" class="each-description w-25">
                    <span class="desc-label">Insurance Discount</span>
                    <span class="value" *ngIf="selectedJob?.updatedData?.overageCoInsurance">${{selectedJob?.updatedData?.overageCoInsurance}}</span>
                    <span class="value" *ngIf="!selectedJob?.updatedData?.overageCoInsurance">$0.00</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Total Discounts</span>
                    <span class="value" *ngIf="selectedJob?.updatedData?.total_discount">${{selectedJob?.updatedData?.total_discount | number :'1.2-2'}}</span>
                    <span class="value" *ngIf="!selectedJob?.updatedData?.total_discount">$0.00</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Ship Speed</span>
                    <span class="value">{{selectedJob?.updatedData?.shipSpeed || '--' | title }}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Shipping Fees</span>
                    <!-- <span class="value">{{selectedJob?.updatedData?.shippingFees | currency:'USD' || '--'}}</span> -->
                    <span class="value" *ngIf="selectedJob?.updatedData?.shippingFees">${{selectedJob?.updatedData?.shippingFees}}</span>
                    <span class="value" *ngIf="!selectedJob?.updatedData?.shippingFees">$0.00</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Taxes</span>
                    <!-- <span class="value">{{selectedJob?.updatedData?.tax | currency:'USD' || '--'}}</span> -->
                    <span class="value" *ngIf="selectedJob?.updatedData?.tax">${{selectedJob?.updatedData?.tax}}</span>
                    <span class="value" *ngIf="!selectedJob?.updatedData?.tax">$0.00</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Processing Fees</span>
                    <span class="value">{{selectedJob?.updatedData?.Credit_Card_Processing_Fees || '--'}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Net Sale</span>
                    <!-- <span class="value">{{selectedJob?.updatedData?.Total_Due_Today | currency:'USD' || '--'}}</span> -->
                    <span class="value">{{ selectedJob?.updatedData?.Total_Due_Today_Reciept | toNumber | currency:'USD'}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Card Type</span>
                    <span class="value text-capitalize">{{selectedJob?.updatedData?.paymentMethod || '--'}}</span>
                </li>
                <li class="each-description w-25">
                    <span class="desc-label">Last 4</span>
                    <span class="value text-capitalize">{{selectedJob?.updatedData?.cardLastNumber || '--'}}</span>
                </li>
                <!-- <li class="each-description w-25">
                    <span class="desc-label">Employee Name</span>
                    <span class="value">{{selectedJob?.updatedData?.employeeName || '--'}}</span>
                </li> -->
            </ul>
            
        </div>
        <!-- patient Information -->


       </div>

</div>