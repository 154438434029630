import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Data } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-trial-batch-view',
  templateUrl: './trial-batch-view.component.html',
  styleUrls: ['./trial-batch-view.component.css']
})
export class TrialBatchViewComponent implements OnInit {

  public onClose: Subject<boolean>;
  user:any;
  batchId:any;
  storeId:any={};
  jobs:any[]=[];
  filteredJobs:any[]=[];
  actions:false;
  isReceived:boolean=false;
  scannerMode=false;
  search='';
  scanner='';
  orderDate:Date;
  @ViewChild('scannerModeEl') scannerModeEl:ElementRef;
  
  constructor(
    private api:ApiService,
    private bsModalRef: BsModalRef<TrialBatchViewComponent>,
    private toastr:ToastrService,
    private ds:DataService
  ) {
    this.user = this.api.user;
   }

  ngOnInit(): void {
    this.filteredJobs = JSON.parse(JSON.stringify(this.jobs));
    if(!this.orderDate){
      this.orderDate = this.jobs[0]['createdAt'];
    }
  }

  bulkRecievedChange(){
    console.log('isReceived',this.isReceived)
    let input:any={};
    input.isReceived = this.isReceived;
    input.jobIds = this.jobs.map(item=>item._id);
    this.api.putData('jobs/received',input).subscribe(res=>{
      this.jobs.map(item=>{
        if(input.jobIds.includes(item._id)){
          item.isReceived = this.isReceived
        }
      })
    });
  }

  singleRecievedChange(item){
    console.log('isReceived 1',item.isReceived);
    setTimeout(()=>{
      console.log('isReceived 2',item.isReceived);
      let input:any={};
      input.isReceived = item.isReceived;
      input.jobIds = [item._id];
      this.api.putData('jobs/received',input).subscribe(res=>{
        // this.trialJobs.map(item=>{
        //   if(input.jobIds.includes(item._id)){
        //     item.isReceived = item.isReceived
        //   }
        //   return item;
        // })
      });
    },500);

  }

  onChangeQuantity(index){
    let item = this.jobs[index];
    this.api.putData(`jobsBatchTrial/${item._id}`,{Soft_CL_Lens_Left_Quantity:item.Soft_CL_Lens_Left_Quantity}).subscribe(res=>{
      this.toastr.success('Success'); 
    },error=>{
      this.toastr.error(error.error.message); 
    });
  }

  deleteJob(index){
    let item = this.jobs[index];
    this.api.deleteData(`jobs/${item._id}`,{}).subscribe(res=>{
      this.jobs.splice(index,1);
    },error=>{
      this.toastr.error(error.error.message); 
    });
  }

  close(){
    this.onClose.next(this.jobs.filter(item=> !item.isReceived).length == 0);
    this.bsModalRef.hide();
  }

  scannerModeToggle(){
    this.search = '';
    this.scannerMode = !this.scannerMode;
    setTimeout(()=>{
      this.scannerModeEl.nativeElement.focus();
    },200);
  }

  initScan(){
    let findIndex = this.jobs.findIndex(item=>item.leftUPC== this.scanner);
    if(findIndex > -1){
      this.jobs[findIndex]['isReceived'] = true;
      // console.log(this.jobs[findIndex]);
      // console.log(this.jobs[findIndex]['isReceived']);
      // this.singleRecievedChange(this.jobs[findIndex]);
      let item = this.jobs[findIndex];
      let input:any={};
      input.isReceived = item.isReceived;
      input.batchId = this.batchId;
      input.upc = [item.leftUPC];
      this.api.putData('jobs/received/bulk',input).subscribe(res=>{
        this.toastr.success('Success');
      },error=>{
        this.toastr.error(error.error.message);
      },()=>{
        this.scanner = '';
      });
    }else{
      this.toastr.error('UPC not found');
    }
  }

  viewTrackingDetails(selectedJob){
    this.ds.openModalTrackingDetails(selectedJob);
  }

}
