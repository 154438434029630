import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-outstanding-balance',
  templateUrl: './outstanding-balance.component.html',
  styleUrls: ['./outstanding-balance.component.css']
})
export class OutstandingBalanceComponent implements OnInit {

  public onClose: Subject<boolean>;
  selectedJob:any={};
  outstanding:number;

  constructor(
    private bsModalRef: BsModalRef<OutstandingBalanceComponent>,
  ) { 
    this.onClose = new Subject();
  }

  ngOnInit(): void {
    if(!this.selectedJob.Soft_CL_Payment_Amount){
      this.selectedJob.Soft_CL_Payment_Amount = 0;
    }
    this.outstanding = parseFloat(this.selectedJob.Soft_CL_Sales_Amount) - parseFloat(this.selectedJob.Soft_CL_Payment_Amount);
  }

  proceed(){
    this.onClose.next(true);
    this.bsModalRef.hide();
  }
  
  close(){
    this.bsModalRef.hide();
  }
  
}
