<div class="ticket-details">
    <div class="text-center title">Ready to Process ・ 1 </div>
    <div class="ticket-add mt-2">
        <div class="table-responsive">
            <!-- <h5 class="text-center">Ready to Process ・ {{jobs?.length || 0}} 
                <img class="csvExport" (click)="exportToCSV()" src="assets/images/csv-export.png" alt="" />
            </h5>     -->
            <table class="table table-common" aria-describedby="list" style="border: transparent;">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Patient</th>
                    <th>Office</th>
                    <th>Order Number</th>
                    <th>Job Type</th>
                    <th>SubType</th>
                    <th>Lab</th>
                    <!-- <th>Comment</th> -->
                    <th>Status</th>
                    <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="cursor-pointer">
                    <td>1</td>
                    <td width="280px">
                        <a href="javascript://" class="text-capitalize">{{selectedJob?.patientId?.patientName}}</a>
                    </td>
                    <td>{{selectedJob?.storeId?.name}}</td>
                    <td>{{selectedJob?.jobNumber}}</td>
                    <td>{{selectedJob?.jobType}}</td>
                    <td>{{selectedJob?.jobSubType || '--'}}</td>
                    <td>{{selectedJob?.labId?.name || '--'}}</td>
                    <!-- <td>
                        <a (click)="addComment(selectedJob)" href="javascript://">Add comment</a>
                    </td> -->
                    <td>{{selectedJob?.status | status}}</td>
                    <td>
                        <div class="dropdown region-dropdown">
                            <button type="button" class="btn btn-purple form-control dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                {{selectedJob?.status | status}}
                            </button>
                            <ul class="dropdown-menu dropdown-menu-right">
                                <li *ngFor="let status of getFilteredMessages(selectedJob)" >
                                    <a *ngIf="status.status=='active'" (click)="changeStatus(selectedJob, status.title)" class="dropdown-item dropdown-selectedJob" href="javascript://">{{status?.title | status}}</a>
                                    <a *ngIf="status.status=='inactive'" class="dropdown-item dropdown-selectedJob disabled" href="javascript://">{{status?.title | status}}</a>
                                </li>
                            </ul>
                        </div>
                    </td>
                    </tr>
                    <tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal-footer justify-content-center">
        <button (click)="hide()" type="button" class="btn btn-cancel" data-bs-dismiss="modal">Close</button>
    </div>
    
    <!-- <pre>{{statusTemplate|json}}</pre> -->
</div>