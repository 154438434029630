<div class="detail-modal">
    <div class="modal-header">
        <h3 class="modal-title">Potential Outstanding Balance</h3>
    </div>
    <div class="detail-content p-3">
        <div class="balance text-red fw-bold">{{outstanding | currency: 'USD'}}</div>
        <div class="alert alert-out">
            This patient didn't pay in full at the time of sale and has
            an outstanding balance on this order of {{outstanding | currency: 'USD'}}. Prior to
            dispensing out of Arrellio, please take payment and
            appropriately apply the payment in your practice
            management system to said patient's profile
        </div>
       <div class="d-flex justify-content-around align-items-center mt-4 mb-4">
            <div class="text-center">
                <div class="out-title">Total Amount</div>          
                <div class="out-val">{{selectedJob?.Soft_CL_Sales_Amount | currency: 'USD'}}</div>
            </div>
            <div class="text-center">
                <div class="out-title">Paid</div>       
                <div class="out-val">{{selectedJob?.Soft_CL_Payment_Amount | currency: 'USD'}}</div>
            </div>
            <div class="text-center">
                <div class="out-title">Outstanding</div>   
                <div class="out-val">{{outstanding | currency: 'USD'}}</div>
            </div>
       </div>
    </div>

    <div class="modal-footer text-center" style=" justify-content: center;">
        <button (click)="close()" class="btn btn-danger mb-3">No, don't update the status</button>
        <button (click)="proceed()" class="btn btn-success mb-3">Yes, update the status</button>
    </div>
</div>
