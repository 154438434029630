<div class="detail-modal">
    <div class="detail-content">
        <div class="modal-header">
            <h3 class="modal-title">Develoepr Mode</h3>
        </div>
        <div class="p-2">
            <pre>{{user | json}}</pre>
        </div>
    </div>

    <div class="modal-footer text-center" style=" justify-content: center;">
        <button (click)="close()" class="btn btn-danger mb-3">Close</button>
    </div>
</div>