<div class="modal-content">
    <img (click)="close()" src="assets/images/orange-cross.png" alt="close" class="orange-cross" >
    <div class="modal-header">
        <div class="d-flex align-items-center">
            <h3 *ngIf="actions"class="modal-title">Trial Order</h3>
            <h3 *ngIf="!actions"class="modal-title">Trial Order | Office Stock | Batch Review</h3>
            <div class="d-flex form-group flex-btn-group icon-group-left ms-3">
                <input type="text" [(ngModel)]="search" placeholder="Search by name..." class="form-control" [ngClass]="{'d-none':scannerMode}">
                <input #scannerModeEl type="number" [(ngModel)]="scanner" (keyup.enter)="initScan()" placeholder="Scan the barcode" class="form-control" [ngClass]="{'d-none':!scannerMode}">
                <button *ngIf="actions" (click)="scannerModeToggle()" class="btn scan-btn" [ngClass]="{'inverse':scannerMode}"><img src="assets/images/bulk-icon.svg"/></button>
            </div>
        </div>
        <div class="trial-order-info d-flex">
            <div class="form-group">
                <label>Office name</label>
                <span>{{storeId?.name}}</span>
            </div>
            <div class="form-group">
                <label>office number</label>
                <span>{{storeId?.number}}</span>
            </div>
            <div *ngIf="actions && jobs?.length > 0" class="form-group">
                <label>order date</label>
                <span>{{orderDate | orderDate |date:'MMM d, YYYY'}}</span>
            </div>
        </div>
    </div>
    <div class="modal-body" style="padding:0px;">
        <div class="table-responsive scrollbar-x tableFixedHeader">
            <table class="table table-common v-middel">
                <thead>
                    <tr>
                        <th *ngIf="actions">
                            <div class="form-group checkbox-custom-group">
                                <div class="text-center">
                                    <input [(ngModel)]="isReceived" (change)="bulkRecievedChange()" type="checkbox" style="height: 34px;width: 34px;" />
                                </div>
                            </div>
                        </th>
                        <th>#</th>
                        <th>Series Name</th>
                        <th>Base Curve</th>
                        <th>Diameter</th>
                        <th>Sphere</th>
                        <th>Cylinder</th>
                        <th>Axis</th>
                        <th>Add</th>
                        <th>Color</th>
                        <th>Lens Count</th>
                        <th>Quantity</th>
                        <th *ngIf="actions">Status</th>
                        <th *ngIf="actions">Tracking</th>
                        <th *ngIf="!actions">
                            Delete
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of jobs | searchByKey: 'Soft_CL_Lens_Left_Style': search ; let index=index">
                        <td *ngIf="actions">
                            <div class="form-group checkbox-custom-group">
                                <div class="text-center">
                                    <input [(ngModel)]="item.isReceived" (click)="singleRecievedChange(item)" type="checkbox" style="height: 34px;width: 34px;" />
                                </div>
                            </div>
                        </td>
                        <td>{{index+1}}</td>
                        <td><span class="elip">{{item?.Soft_CL_Lens_Left_Style}}</span></td>
                        <td>{{item?.Soft_CL_Lens_Left_Base}}</td>
                        <td>{{item?.Soft_CL_Lens_Left_Diameter}}</td>
                        <td>{{item?.Soft_CL_Lens_Left_Sphere}}</td>
                        <td>{{item?.Soft_CL_Lens_Left_Cylinder}}</td>
                        <td>{{item?.Soft_CL_Lens_Left_Axis}}</td>
                        <td>{{item?.Soft_CL_Lens_Left_Add}}</td>
                        <td>{{item?.Soft_CL_Lens_Left_Color}}</td>
                        <td>{{item?.leftCatalogPackName || item?.rightCatalogPackName || '--'}}</td>
                        <td>
                            <span *ngIf="actions">{{item?.Soft_CL_Lens_Left_Quantity}}</span>
                            <span *ngIf="!actions"><input [(ngModel)]="item.Soft_CL_Lens_Left_Quantity" (change)="onChangeQuantity(index)" type="text" class="form-control" style="width:70px"/></span>
                        </td>
                        <td *ngIf="actions">
                            <span class="btn2 btn-purple">{{item?.status | status}}</span>
                        </td>
                       <td *ngIf="actions">
                            <img *ngIf="item?.status!='On Backorder' && item?.trackingNumber" (click)="viewTrackingDetails(item)"  src="assets/images/trackingNumber.svg" alt="shipped" class="cursor-pointer"/>
                       </td>
                        <td *ngIf="!actions">
                            <!-- <img (click)="deleteJob(index)" src="assets/images/red-trash.svg" class="cursor-pointer"/> -->
                            <a (click)="deleteJob(index)" href="javascript://" class="btn btn-sm btn-danger">
                                <span class="fa-solid fa-trash"></span>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>