import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {

  transform(value: Date): string {
    let today = moment();
    let birthdate = moment(value);
    let years = today.diff(birthdate, 'years');
    // console.log(value,'years',years)
    if(years <= 0){
      return '0 year';
    }
    if(years == 1){
      return '1 year';
    }
    let html:string = years + " years";
    // console.log(html)
    // html += today.subtract(years, 'years').diff(birthdate, 'months') + " mo";

    return html;
}

}
