import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { TicketService } from 'src/app/services/ticket.service';

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.css']
})
export class CreateTicketComponent implements OnInit {

  url:any;
  file:any;
  newReason:any;
  newMessage:any;
  selectedJob:any;
  selectedTicket:any={};
  readyToReopen=false;
  prefix='Eyeglass_';

  constructor(
    private api:ApiService,
    private ticketService:TicketService,
    private toastrService:ToastrService,
    private modalService: BsModalService,
    private router: Router,
    private ds:DataService
  ) { }

  ngOnInit(): void {
    console.log('selectedJob',this.selectedJob);
    if(this.selectedJob.Soft_CL_Order_Date){
      this.prefix = 'Soft_CL_';
    }
    if(this.selectedJob.Hard_CL_Order_Date){
      this.prefix = 'Hard_CL_';
    }
    if(this.selectedJob.ticketDetail && this.selectedJob.ticketDetail.length > 0){
      this.readyToReopen = true;
      this.selectedTicket = this.selectedJob.ticketDetail[0];
      // this.newReason = this.selectedTicket.reason;
      // this.newMessage = this.selectedTicket.message;
    }
  }

  selectTicket(item){
    this.selectedTicket = item;
    this.newReason = this.selectedTicket.reason;
    this.newMessage = this.selectedTicket.message;
  }

  onSelectFile(event): any {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
          this.url = e.target.result;
          this.file = event.target.files[0];
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  uploadImage(){
    return new Promise((resolve,reject)=>{
      const formData = new FormData();
      formData.append('image', this.file);
      this.api.postData('common/uploadImage',formData).subscribe(res=>{
        resolve(res);
      },error=>{
        reject();
      });
    })
  }

  async createNewTicket(){
    let input:any={};
    if(!this.newReason){
      this.toastrService.error('Please select a reason'); return;
    }
    if(!this.newMessage){
      this.toastrService.error('Please write a message'); return;
    }
    if(this.file){
      let uploadData = await this.uploadImage();
      input.attachment = [uploadData];
    }
    input.text = this.newMessage;
    input.reason = this.newReason;
    input.jobId = this.selectedJob._id;

    this.ticketService.postData('tickets',input).subscribe(res=>{
      this.hide();
    },error=>{
      this.toastrService.error(error.error.message);
    });
  }

  ticketDetails(ticket){
    this.hide();
    this.router.navigate([`admin/tickets/${ticket.jobId}`,{ticketId:ticket._id}]);
  }

  async reopenTicket(ticketId){
    let input:any={};
    if(this.file){
      let uploadData = await this.uploadImage();
      input.attachment = [uploadData];
    }
    input.text = this.newMessage;
    input.reason = this.newReason;
    
    input.ticketStatus = 'ACTIVE';
    input.ticketId = ticketId;
    this.ticketService.putData(`tickets/${this.selectedJob._id}`,input).subscribe(res=>{
      this.hide();
    },error=>{
      this.toastrService.error(error.error.message);
    });
  }

  hide(){
    this.modalService.hide();
  }

  viewAllFields(){
    this.ds.openModalJobDetails(this.selectedJob);
  }

  viewTrackingDetails(){
    this.ds.openModalTrackingDetails(this.selectedJob);
  }
  
  sum(a=0,b=0):number{
    return this.ds.sum(a,b);
  }

  lower(text){
    if(!text){
      return false;
    }
    return text.toLowerCase();
  }
  
}
