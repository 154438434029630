import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    switch(value){
      case 'ORDERPLACED'        : value = 'Order Placed'; break;
      case 'INPROGRESS'         : value = 'In Progress'; break;
      case 'DELIVERED'          : value = 'Delivered'; break;
      case 'INSTORE'            : value = 'In Office'; break;
      case 'REMAKE'             : value = 'Remake'; break;
      case 'REORDER'            : value = 'Reorder'; break;
      case 'WAITINGFORSHIPMENT' : value = 'Waiting For Shipment'; break;
      case 'READYTODISPENSE'    : value = 'Ready To Dispense'; break;
      case 'RESENT'             : value = 'Resent'; break;
      case 'INLAB'              : value = 'In Lab'; break;
      case 'ONTHEWAY'           : value = 'On The Way'; break;
      case 'NEW'                : value = 'New'; break;
      case 'UPDATED'            : value = 'Updated'; break;
      case 'SHIPPEDTOSTORE'     : value = 'Direct To Office'; break;
      case 'SHIPPEDTOPATIENT'   : value = 'Direct To Patient'; break;
      case 'New Jobs'           : value = 'New Orders'; break;
      case 'Job Dispensed'      : value = 'Order Dispensed'; break;
      case 'Job Delivered'      : value = 'Order Delivered'; break;
      default : 
    }
    return value;
  }

}

// INSTORE    : "INSTORE",
// REMAKE   : "REMAKE",
// REORDER      : "REORDER",
// WAITINGFORSHIPMENT: "WAITINGFORSHIPMENT",
// READYTODISPENSE: "READYTODISPENSE",
// RESENT: "RESENT",
// INLAB: "INLAB",
// ONTHEWAY: "ONTHEWAY",
// NEW: "NEW",
// UPDATED: "UPDATED",