import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchByKey'
})
export class SearchByKeyPipe implements PipeTransform {

  transform(items: any=[], filterKey?: any, filterValue:any = true): any {
    if(filterKey && filterValue){
      console.log('filterByKey',filterKey , filterValue);
      const regex = new RegExp(filterValue, "gi");
      return items.filter(data =>{
        if(data[filterKey].match(regex)){
          return data;
        }
      });
    }
    return items;
  }

}
