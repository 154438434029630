import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-developer-mode',
  templateUrl: './developer-mode.component.html',
  styleUrls: ['./developer-mode.component.css']
})
export class DeveloperModeComponent implements OnInit {

  user:any;

  constructor(
    private api:ApiService,
    private bsModalRef: BsModalRef<DeveloperModeComponent>,
  ) { 
    this.user = this.api.user;
  }

  ngOnInit(): void {
  }

  close(){
    this.bsModalRef.hide();
  }

}
