import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-check-inventory',
  templateUrl: './check-inventory.component.html',
  styleUrls: ['./check-inventory.component.css']
})
export class CheckInventoryComponent implements OnInit {

  public onClose: Subject<boolean>;
  count:number = 0;
  
  constructor(
    private bsModalRef: BsModalRef<CheckInventoryComponent>,
  ) { }

  ngOnInit(): void {
  }

  proceed(){
    this.onClose.next(true);
    this.bsModalRef.hide();
  }
  close(){
    this.bsModalRef.hide();
  }
}
