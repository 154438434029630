import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppLoadService {

  environment = environment;
  eventSource:any;

  constructor(
    private api:ApiService,
    private http:HttpClient,
    private router:Router
  ) { }

  init(): Promise<any> {
    // return Promise.resolve('IMMIDIATE RESOLVE - appInitService');
    return new Promise<any>((resolve, reject) => {
      if(localStorage.getItem('access_token')){
        this.api.getData('dashboard/loginDetails',{}).subscribe(loginDetails => {
            loginDetails = this.api.parseLoginDetails(loginDetails);
            console.log('loginDetails',loginDetails)
            this.api.updateLoginData(loginDetails);
            this.api.enterpriseId = loginDetails.enterpriseId._id;
            // this.api.getTransactions()
            resolve('success');
        },error=>{
            console.log('ERROR',error);
            resolve(error);
        });
      }else{
        // this.router.navigate(['/']);
        resolve('error');
      }
    });
  }
}
