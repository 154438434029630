<!-- <button class="close-modal" data-dismiss="modal" (click)="hide()">&times;</button> -->
<div class="patient-popup">
    <div class="table-title">
        <h3 class="dashboard-title">Patient Detail</h3>
    </div>
    <hr/>
    <div class="detail-section">
        <div class="row">
            <div class="col-md-4">
                <div class="patient-detail">
                    <h3 class="patient-name text-center">{{selectedPatient?.patientName}}</h3>
                    <p class="patient-id text-center">Order Number:<strong> {{selectedJob?.jobNumber || '--'}}</strong></p>
                    <ul class="patient-info">
                        <li>
                            <span class="fa-solid fa-cake-candles"></span>
                            <span>
                                {{selectedJob?.patientId?.patientDOB | date: 'MM/dd/yyyy'}}
                                <a>({{selectedJob?.patientId?.patientDOB | age}})</a>
                            </span>
                        </li>
                        <li>
                            <span class="fa-solid fa-phone-volume"></span> 
                            <a><span>{{selectedJob?.patientId?.phoneNumber | phonenumber}}</span></a> 
                            <a *ngIf="selectedJob?.patientId?.type && selectedJob?.patientId?.type !='mobile'" class="userRole redbg">{{selectedJob?.patientId?.type}}</a>                                           
                        </li>
                        <li>
                            <span class="fa-solid fa-envelope"></span>
                            <a><span>{{selectedJob?.patientId?.email || 'N/A'}}</span></a>    
                            <a *ngIf="selectedJob?.patientId?.emailType && selectedJob?.patientId?.emailType !='valid'" class="userRole redbg">invalid</a>                                        
                        </li>
                        <li *ngIf="selectedJob?.patientId?.Patient_Address_1" class="d-flex">
                            <span class="fa-solid fa-house"></span>
                            <span>
                                <p class="mx-0 my-0">{{selectedJob?.patientId?.Patient_Address_1}}</p>
                                <p *ngIf="selectedJob?.patientId?.Patient_Address_2" class="mx-0 my-0">{{selectedJob?.patientId?.Patient_Address_2}}</p>
                                <p class="mx-0 my-0">
                                    {{selectedJob?.patientId?.Patient_City ? selectedJob?.patientId?.Patient_City+',' : ''}} 
                                    {{selectedJob?.patientId?.Patient_State ? selectedJob?.patientId?.Patient_State+',' : ''}} 
                                    {{selectedJob?.patientId?.Patient_Zip_Code}}
                                </p>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-8 position-relative">
    
                <ul class="patient-description">
                    <li class="each-description">
                        <span class="desc-label">Order Number</span>
                        <span class="value">{{selectedJob?.jobNumber}}</span>
                    </li>
                    <li class="each-description">
                        <span class="desc-label">Order Date</span>
                        <span class="value">{{selectedJob?.orderDate | orderDate | date:'MM/dd/yyyy'}}</span>
                    </li>
                    <li class="each-description">
                        <span class="desc-label">Order Type</span>
                        <span class="value">{{selectedJob[prefix+'Acuity_Type']}} ({{selectedJob['jobSubType']}})</span>
                    </li>

                    <li class="each-description">
                        <span class="desc-label">Office Name</span>
                        <span class="value">{{selectedJob?.storeId?.name}}</span>
                    </li>
                    <li class="each-description">
                        <span class="desc-label">Office Number</span>
                        <span class="value">{{selectedJob?.storeId?.number}}</span>
                    </li>
                   
                    <li class="each-description">
                        <span class="desc-label">Consultant </span>
                        <span class="value text-capitalize">{{ lower(selectedJob[prefix+'Consultant']) || '--'}}</span>
                    </li>
                    <li class="each-description">
                        <span class="desc-label">Lab Name</span>
                        <span class="value">{{selectedJob?.labId?.name || '--'}}</span>
                    </li>
                    <li class="each-description">
                        <span class="desc-label">Sales Amount</span>
                        <span class="value">{{sum(selectedJob[prefix+'Sales_Amount'],selectedJob[prefix+'Insurance_Allowance']) | currency:'USD'}}</span>
                    </li>
                    <li class="each-description">
                        <span class="desc-label">Tracking Number</span>
                        <span *ngIf="selectedJob?.trackingNumber" (click)="viewTrackingDetails()" class="value trackingg">View Log</span>
                        <span *ngIf="!selectedJob?.trackingNumber" class="value">--</span>
                    </li>

                    <!-- Eyeglass -->
                    <li *ngIf="prefix == 'Eyeglass_'"  class="each-description">
                        <span class="desc-label">Frame Brand</span>
                        <span class="value">{{selectedJob?.Eyeglass_Frame_Brand || '--'}}</span>
                    </li>
                    <li *ngIf="prefix == 'Eyeglass_'"  class="each-description">
                        <span class="desc-label">Frame Color</span>
                        <span class="value">{{selectedJob?.Eyeglass_Frame_Color || '--'}}</span>
                    </li>
                    <li *ngIf="prefix == 'Eyeglass_'"  class="each-description">
                        <span class="desc-label">Lens Style</span>
                        <span class="value">{{selectedJob?.Eyeglass_Lens_Style || '--'}}</span>
                    </li>
                   
                    <!-- Soft CL -->
                    <li *ngIf="prefix == 'Soft_CL_'"  class="each-description">
                        <span class="desc-label">Manufacturer</span>
                        <span class="value">{{selectedJob?.Soft_CL_Lens_Right_Manufacturer || selectedJob?.Soft_CL_Lens_Left_Manufacturer || '--'}}</span>
                    </li>
                    <li *ngIf="prefix == 'Soft_CL_'"  class="each-description">
                        <span class="desc-label">Brand</span>
                        <span class="value">{{selectedJob?.Soft_CL_Lens_Right_Style || selectedJob?.Soft_CL_Lens_Left_Style || '--'}}</span>
                    </li>
                    <li *ngIf="prefix == 'Soft_CL_'"  class="each-description">
                        <span class="desc-label">Total Quantity</span>
                        <span class="value">{{ sum(selectedJob?.Soft_CL_Lens_Right_Quantity,selectedJob?.Soft_CL_Lens_Left_Quantity)}}</span>
                    </li>

                    <!-- Hard CL -->
                    <li *ngIf="prefix == 'Hard_CL_'"  class="each-description">
                        <span class="desc-label">Manufacturer</span>
                        <span class="value">{{selectedJob?.Hard_CL_Lens_Right_Manufacturer || selectedJob?.Hard_CL_Lens_Left_Manufacturer || '--'}}</span>
                    </li>
                    <li *ngIf="prefix == 'Hard_CL_'"  class="each-description">
                        <span class="desc-label">Brand</span>
                        <span class="value">{{selectedJob?.Hard_CL_Lens_Right_Style || selectedJob?.Hard_CL_Lens_Left_Style || '--'}}</span>
                    </li>
                    <li *ngIf="prefix == 'Hard_CL_'"  class="each-description">
                        <span class="desc-label">Total Quantity</span>
                        <span class="value">{{ sum(selectedJob?.Hard_CL_Lens_Right_Quantity,selectedJob?.Hard_CL_Lens_Left_Quantity)  || 0}}</span>
                    </li>

                </ul> 
    
                <div (click)="viewAllFields()"  class="detail-btn position-absolute">
                    <span class="fa-solid fa-arrows-alt"></span>
                </div>
                
            </div>
        </div>
    </div>

    <div class="modal-footer justify-content-center">
        <button (click)="hide()" type="button" class="btn btn-grey" data-dismiss="modal">Close</button>
    </div>

</div>