<div class="ticket-details">
    <div class="text-center title">{{readyToReopen ?  'Tickets History' : 'Create Ticket' }}</div>
    <div class="ticket-add mt-2">
        <form action="" class="dashboard-form">
            <div *ngIf="readyToReopen" class="row">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-common" aria-describedby="tickets">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Patient</th>
                                    <th>Office</th>
                                    <th>Order Number</th>
                                    <th>Reason</th>
                                    <th>Lab</th>
                                    <th>Created Date</th>
                                    <th>Status</th>
                                    <th>Selected</th> 
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of selectedJob?.ticketDetail; let index=index" 
                                (click)="selectTicket(item)"
                                [ngClass]="{'selected':item?._id == selectedTicket?._id}"
                                class="cursor-pointer">
                                    <td>{{tickets?.skip + index + 1}}</td>
                                    <td>
                                        <a href="javascript://" class="text-capitalize">{{selectedJob?.patientId?.patientName}}</a>
                                    </td>
                                    <td>{{selectedJob?.storeId?.name}}</td>
                                    <td>{{selectedJob?.jobNumber}}</td>
                                    <td>{{item?.reason}}</td>
                                    <td>{{selectedJob?.labId?.name}}</td>
                                    <td>{{item?.createdAt | date:'MM/dd/yyyy'}}</td>
                                    <td>
                                        <a [ngClass]="{'text-danger':item.status == 'ACTIVE', 'text-success':item.status != 'ACTIVE' }">{{item?.status}}</a>
                                    </td>
                                    <td>
                                        <!-- <img src="assets/images/success-icon.png" class="selectedIcon"/> -->
                                        <button *ngIf="item?.status == 'ACTIVE'" (click)="ticketDetails(item)" type="button" class="btn btn-reopen mx-2">View</button>
                                        <button *ngIf="item?.status != 'ACTIVE'" (click)="reopenTicket(item._id)" type="button" class="btn btn-reopen mx-2">Reopen</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <hr>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="detail-section">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="patient-detail">
                                    <h3 class="patient-name text-center">{{selectedJob?.patientId?.patientName}}</h3>
                                    <p class="patient-id text-center">Order Number:<strong>
                                            {{selectedJob?.jobNumber}}</strong></p>
                                    <ul class="patient-info">
                                        <li>
                                            <span class="fa-solid fa-cake-candles"></span>
                                            <span>
                                                {{selectedJob?.patientId?.patientDOB | date: 'MM/dd/yyyy'}}
                                                <a>({{selectedJob?.patientId?.patientDOB | age}})</a>
                                            </span>
                                        </li>
                                        <li>
                                            <span class="fa-solid fa-phone-volume"></span>
                                            <a><span>{{selectedJob?.patientId?.phoneNumber | phonenumber}}</span></a>
                                            <a *ngIf="selectedJob?.patientId?.type && selectedJob?.patientId?.type !='mobile'" class="userRole redbg">{{selectedJob?.patientId?.type}}</a> 
                                        </li>
                                        <li>
                                            <span class="fa-solid fa-envelope"></span>
                                            <a><span>{{selectedJob?.patientId?.email || 'N/A'}}</span></a>
                                            <a *ngIf="selectedJob?.patientId?.emailTyp && selectedJob?.patientId?.emailType !='valid'" class="userRole redbg">invalid</a>
                                        </li>
                                        <li *ngIf="selectedJob?.patientId?.Patient_Address_1" class="d-flex">
                                            <span class="fa-solid fa-house"></span>
                                            <span>
                                                <p class="mx-0 my-0">{{selectedJob?.patientId?.Patient_Address_1}}</p>
                                                <p *ngIf="selectedJob?.patientId?.Patient_Address_2" class="mx-0 my-0">{{selectedJob?.patientId?.Patient_Address_2}}</p>
                                                <p class="mx-0 my-0">
                                                    {{selectedJob?.patientId?.Patient_City ? selectedJob?.patientId?.Patient_City+',' : ''}} 
                                                    {{selectedJob?.patientId?.Patient_State ? selectedJob?.patientId?.Patient_State+',' : ''}} 
                                                    {{selectedJob?.patientId?.Patient_Zip_Code}}
                                                </p>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <ul class="patient-description">
                                    <li class="each-description">
                                        <span class="desc-label">Order Number</span>
                                        <span class="value">{{selectedJob?.jobNumber}}</span>
                                    </li>
                                    <li class="each-description">
                                        <span class="desc-label">Order Date</span>
                                        <span class="value">{{selectedJob?.orderDate | orderDate | date:'MM/dd/yyyy'}}</span>
                                    </li>
                                    <li class="each-description">
                                        <span class="desc-label">Order Type</span>
                                        <span class="value">{{selectedJob[prefix+'Acuity_Type']}} ({{selectedJob['jobSubType']}})</span>
                                    </li>

                                    <li class="each-description">
                                        <span class="desc-label">Store Name</span>
                                        <span class="value">{{selectedJob?.storeId?.name}}</span>
                                    </li>
                                    <li class="each-description">
                                        <span class="desc-label">Store Number</span>
                                        <span class="value">{{selectedJob?.storeId?.number}}</span>
                                    </li>
                                   
                                    <li class="each-description">
                                        <span class="desc-label">Consultant </span>
                                        <span class="value text-capitalize">{{ lower(selectedJob[prefix+'Consultant']) || '--'}}</span>
                                    </li>
                                    <li class="each-description">
                                        <span class="desc-label">Lab Name</span>
                                        <span class="value">{{selectedJob?.labId?.name || '--'}}</span>
                                    </li>
                                    <li class="each-description">
                                        <span class="desc-label">Sales Amount</span>
                                        <span class="value">{{sum(selectedJob[prefix+'Sales_Amount'],selectedJob[prefix+'Insurance_Allowance']) | currency:'USD'}}</span>
                                    </li>
                                    <li class="each-description">
                                        <span class="desc-label">Tracking Number</span>
                                        <span *ngIf="selectedJob?.trackingNumber" (click)="viewTrackingDetails()" class="value trackingg">View Log</span>
                                        <span *ngIf="!selectedJob?.trackingNumber" class="value">--</span>
                                    </li>

                                    <!-- Eyeglass -->
                                    <li *ngIf="prefix == 'Eyeglass_'"  class="each-description">
                                        <span class="desc-label">Frame Brand</span>
                                        <span class="value">{{selectedJob?.Eyeglass_Frame_Brand || '--'}}</span>
                                    </li>
                                    <li *ngIf="prefix == 'Eyeglass_'"  class="each-description">
                                        <span class="desc-label">Frame Color</span>
                                        <span class="value">{{selectedJob?.Eyeglass_Frame_Color || '--'}}</span>
                                    </li>
                                    <li *ngIf="prefix == 'Eyeglass_'"  class="each-description">
                                        <span class="desc-label">Lens Style</span>
                                        <span class="value">{{selectedJob?.Eyeglass_Lens_Style || '--'}}</span>
                                    </li>
                                   
                                    <!-- Soft CL -->
                                    <li *ngIf="prefix == 'Soft_CL_'"  class="each-description">
                                        <span class="desc-label">Manufacturer</span>
                                        <span class="value">{{selectedJob?.Soft_CL_Lens_Right_Manufacturer || selectedJob?.Soft_CL_Lens_Left_Manufacturer || '--'}}</span>
                                    </li>
                                    <li *ngIf="prefix == 'Soft_CL_'"  class="each-description">
                                        <span class="desc-label">Brand</span>
                                        <span class="value">{{selectedJob?.Soft_CL_Lens_Right_Style || selectedJob?.Soft_CL_Lens_Left_Style || '--'}}</span>
                                    </li>
                                    <li *ngIf="prefix == 'Soft_CL_'"  class="each-description">
                                        <span class="desc-label">Total Quantity</span>
                                        <span class="value">{{ sum(selectedJob?.Soft_CL_Lens_Right_Quantity,selectedJob?.Soft_CL_Lens_Left_Quantity)}}</span>
                                    </li>

                                    <!-- Hard CL -->
                                    <li *ngIf="prefix == 'Hard_CL_'"  class="each-description">
                                        <span class="desc-label">Manufacturer</span>
                                        <span class="value">{{selectedJob?.Hard_CL_Lens_Right_Manufacturer || selectedJob?.Hard_CL_Lens_Left_Manufacturer || '--'}}</span>
                                    </li>
                                    <li *ngIf="prefix == 'Hard_CL_'"  class="each-description">
                                        <span class="desc-label">Brand</span>
                                        <span class="value">{{selectedJob?.Hard_CL_Lens_Right_Style || selectedJob?.Hard_CL_Lens_Left_Style || '--'}}</span>
                                    </li>
                                    <li *ngIf="prefix == 'Hard_CL_'"  class="each-description">
                                        <span class="desc-label">Total Quantity</span>
                                        <span class="value">{{ sum(selectedJob?.Hard_CL_Lens_Right_Quantity,selectedJob?.Hard_CL_Lens_Left_Quantity)  || 0}}</span>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="upload-ticket">
                        <div class="row">
                            <div class="col-md-8 desc-col">

                                <div class="form-group">
                                    <label for="">Reason </label>
                                    <select [(ngModel)]="newReason" class="form-control"
                                        [ngModelOptions]="{standalone: true}">
                                        <option value="General Inquiry" [disabled]="selectedJob?.ticketDetail">General Inquiry – for standard questions about the job</option>
                                        <!-- <option value="Rush Order" [disabled]="selectedJob?.ticketDetail">Rush Order – for immediate prioritization of this patient order</option> -->
                                        <option value="Hot Job" [disabled]="selectedJob?.ticketDetail">Hot Job – for jobs that are taking longer than the predefined set of days by admin</option>
                                        <option value="No Update" [disabled]="selectedJob?.ticketDetail">No Update – for jobs with no status update changes within predefined time by admin</option>
                                        <!-- <option value="Lab Breakage">Lab Breakage – for broken jobs in the lab to warn of potential delay</option> -->
                                        <!-- <option value="Patient Restyle" >Patient Restyle – for patients who want glasses remade or adjusted</option> -->
                                        <!-- <option value="Lens Issue" [disabled]="selectedJob?.ticketDetail">Lens Issue – for incorrect or damaged lenses upon receipt</option> -->
                                        <!-- <option value="Frame Issue" [disabled]="selectedJob?.ticketDetail">Frame Issue – for incorrect or damaged frames upon receipt</option> -->
                                        <option value="Inventory Backorder" [disabled]="selectedJob?.ticketDetail">Inventory Backorder – for noting jobs with inventory on backorder</option>
                                        <option value="Job Cancellation" [disabled]="selectedJob?.ticketDetail">Job Cancellation – for flagging jobs that were canceled/no longer needed</option>
                                        <option value="Remake Order">Remake Order - for jobs that are being remade or reordered for the patient</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label for="">Description</label>
                                    <textarea [(ngModel)]="newMessage" class="form-control"
                                        [ngModelOptions]="{standalone: true}"></textarea>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="">Attachment</label>
                                    <div class="upload-file-custom">
                                        <input (change)="onSelectFile($event)" type="file" class="form-control">
                                        <div *ngIf="!url" class="upload-placeholder">
                                            <div class="icon">
                                                <span class="fa-solid fa-image"></span>
                                            </div>
                                            <div class="drag-text text-center">
                                                <span>Add Photos/Video</span>
                                            </div>
                                        </div>
                                        <div *ngIf="url" class="image-placeholder">
                                            <img [src]="url" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="text-center">
        <button (click)="createNewTicket()" type="button" class="btn btn-create mx-2">Create</button>
        <!-- <button *ngIf="selectedJob?.ticketDetail" (click)="reopenTicket()" type="button" class="btn btn-reopen mx-2">Reopen</button> -->
    </div>
    
</div>