import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { TicketService } from 'src/app/services/ticket.service';

@Component({
  selector: 'app-status-ready-to-process',
  templateUrl: './status-ready-to-process.component.html',
  styleUrls: ['./status-ready-to-process.component.css']
})
export class StatusReadyToProcessComponent implements OnInit {

  selectedJob:any;
  statusTemplate;

  constructor(
    private api:ApiService,
    private ticketService:TicketService,
    private toastrService:ToastrService,
    private modalService: BsModalService,
    private ds:DataService
  ) { }

  ngOnInit(): void {
    console.log('selectedJob',this.selectedJob);
  }

  async changeStatus(job,status){
    console.log(job,status);
    if(job.status == status){return false; }
    if(status=='Job Dispensed'){
      if(!await this.ds.checkOutstandingBalance(this.selectedJob)){
        return false;
      }
    }
    console.log("CHANGE STATUS HIT");
    let input:any = {};
    input.status = status;
    this.api.putData(`jobs/${job._id}`,input).subscribe(r=>{
      job['status'] = status;
      if(status == 'Job Remake'){
        job.ticketDetail = r.ticketDetail;
        this.ds.openModalTicket(job);
      }
    });
  }

  getFilteredMessages(job){
    if(this.statusTemplate[job.jobType]){
      return this.statusTemplate[job.jobType];
    }else{
      return this.statusTemplate[job.jobSubType];
    }
  }


  hide(){
    this.modalService.hide();
  }

}
