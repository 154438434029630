import { Component, OnInit } from '@angular/core';
import { Data } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-single-patient',
  templateUrl: './single-patient.component.html',
  styleUrls: ['./single-patient.component.css']
})
export class SinglePatientComponent implements OnInit {

  selectedPatient:any={};
  selectedJob:any={};
  jobId:any;
  bsModalRef: BsModalRef;
  prefix='Eyeglass_';
  
  constructor(
    private api:ApiService,
    private modalService: BsModalService,
    private ds:DataService,
    private ts:ToastrService
  ) { }

  ngOnInit(): void {
    this.api.showLoader = false;
    this.api.getData(`patients/${this.jobId}`,{}).subscribe(res=>{
      this.selectedJob = res;
      this.selectedPatient = res.patientId;
      if(this.selectedJob.Soft_CL_Order_Date){
        this.prefix = 'Soft_CL_';
      }
      if(this.selectedJob.Hard_CL_Order_Date){
        this.prefix = 'Hard_CL_';
      }
    },error=>{
      this.ts.error(error.error.message);
      this.hide();
    });
  }

  changeJob(event){
    let index:number = event.page || 0;
    this.selectedJob = this.selectedPatient.jobId[index];
    if(this.selectedJob.Soft_CL_Order_Date){
      this.prefix = 'Soft_CL_';
    }
    if(this.selectedJob.Hard_CL_Order_Date){
      this.prefix = 'Hard_CL_';
    }
  }

  hide(){
    this.modalService.hide();
  }

  viewAllFields(){
    this.ds.openModalJobDetails(this.selectedJob);
  }

  viewTrackingDetails(){
    this.ds.openModalTrackingDetails(this.selectedJob);
  }
  
  sum(a=0,b=0):number{
    return this.ds.sum(a,b);
  }

  lower(text){
    if(!text){
      return false;
    }
    return text.toLowerCase();
  }

}
