<div class="detail-modal">
    <div class="detail-content p-3">
        <div class="modal-header">
            <h3 class="modal-title">Pending Inventory Check Orders</h3>
        </div>
        <div class="text-center mt-4 mb-4">
            <img src="assets/images/info-triangle.svg"/>
        </div>
        <div class="alert text-center">
            Are you sure you want to process this batch order? There are {{count}} Direct to Office (DTO) orders pending in the Inventory Check table. 
            These orders could be added to the batch.
        </div>
    </div>

    <div class="modal-footer text-center" style=" justify-content: center;">
        <button (click)="close()" class="btn btn-danger mb-3">Abort</button>
        <button (click)="proceed()" class="btn btn-success mb-3">Process Anyway</button>
    </div>
</div>
